import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { ProjectSummary } from "../../components/projects/ProjectSummary";

const BrooksideSubdivision = () => (
  <Layout>
    <SEO
      title="Featherston Station & Upper Hutt Station Car Park Extensions"
      image={"/featherston.jpg"}
      description="The works for the contract included; site clearance and demolition, excavation, stormwater drainage, ducting for street lighting and CCTV, new areas of car park construction, asphalt and chipseal surfacing, kerbing and footpaths, street lighting and signage and car park markings."
    />

    <div className="row g-0 ">
      <div className="col-lg-6 d-flex align-items-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">
            Featherston Station & Upper Hutt Station Car Park Extensions
          </h1>
          <ProjectSummary
            name="Featherston Station & Upper Hutt Station Car Park Extensions"
            type="Car park and Infrastructure Upgrades"
            location="Featherston, Wairarapa Upper Hutt, Wellington"
            client="Greater Wellington Regional Council"
            engineer="Wade Consulting"
          />
          <p>
            The works for the contract included; site clearance and demolition,
            excavation, stormwater drainage, ducting for street lighting and
            CCTV, new areas of car park construction, asphalt and chipseal
            surfacing, kerbing and footpaths, street lighting and signage and
            car park markings. New stormwater network was installed to the
            existing carpark which included the installation of:
          </p>
          <ul>
            <li>100m of class 4 375mm concrete pipe</li>
            <li>80m of class 4 300mm concrete pipe</li>
            <li>2x 1050dia manholes</li>
            <li>4x UHCC standard sumps with sump leads</li>
            <li>1x3.0m dia up-flo filtration manhole</li>
            <li>40m of gatic slot drain system</li>
          </ul>
        </div>
      </div>
      <div className="col-lg-6 d-flex  align-items-center justify-content-center">
        <div style={{ maxWidth: 566, margin: "auto" }}>
          <StaticImage
            className="mb-3"
            aspectRatio={16 / 14}
            transformOptions={{ cropFocus: "center" }}
            objectPosition="50% 50%"
            src="../../images/featherston.jpg"
            alt="crane"
          />
          <StaticImage
            aspectRatio={16 / 14}
            transformOptions={{ cropFocus: "center" }}
            objectPosition="50% 50%"
            src="../../images/drainage.jpg"
            alt="drain"
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default BrooksideSubdivision;
